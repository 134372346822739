<template>
  <section>
    <!--工具条-->
    <toolbar
      :buttonList="buttonList"
      @callFunction="callFunction"
      :buttonListmsg="buttonListmsg"
    ></toolbar>
    <!--列表-->
    <el-table
      :data="users"
      highlight-current-row
   
      @current-change="selectCurrentRow"
      style="width: 100%"
    >
      <el-table-column type="index" width="20"></el-table-column>
      <el-table-column
        prop="Url"
        label="接口地址"
        min-width="150"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="Name"
        label="描述"
        min-width="150"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="CreatedOn"
        label="创建时间"
        min-width="100"
        :formatter="formatCreateTime"
        sortable
      ></el-table-column>
      <el-table-column
        prop="CreatedBy"
        label="创建者"
        min-width="80"
        sortable
      ></el-table-column>
      <!-- <el-table-column prop = "DefaultAllowed" label = "是否默认接口" width = "" sortable>
         <template slot-scope="scope">
          <el-tag
            :type="scope.row.DefaultAllowed  ? 'success' : 'danger'"
            disable-transitions
          >{{scope.row.DefaultAllowed ? "是":"否"}}</el-tag>
        </template>
      </el-table-column>-->
      <el-table-column prop="Enabled" label="状态" sortable>
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.Enabled ? 'success' : 'danger'"
            disable-transitions
            >{{ scope.row.Enabled ? "正常" : "禁用" }}</el-tag
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :page-count="total"
      :total="totaldata"
    ></el-pagination>

    <!--编辑界面-->
    <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="editForm"
        label-width="80px"
        :rules="editFormRules"
        ref="editForm"
      >
        <el-form-item label="接口地址" prop="Url">
          <el-input v-model="editForm.Url" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="接口描述" prop="Name">
          <el-input v-model="editForm.Name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="Enabled">
          <el-select v-model="editForm.Enabled" placeholder="请选择状态">
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.Url"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="editSubmit"
          :loading="editLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog
      title="新增"
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="addForm"
        label-width="80px"
        :rules="addFormRules"
        ref="addForm"
      >
        <el-form-item label="接口地址" prop="Url">
          <el-input v-model="addForm.Url" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="接口描述" prop="Name">
          <el-input v-model="addForm.Name" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="状态" prop="Enabled">
          <el-select v-model="addForm.Enabled" placeholder="请选择状态">
            <el-option label="激活" value="true"></el-option>
            <el-option label="禁用" value="false"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="addSubmit"
          :loading="addLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
  </section>
</template>

<script>
import util from "../../../util/date";
import {
  getModuleListPage,
  removeModule,
  editModule,
  addModule,
  getModuleList,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";

export default {
  components: { Toolbar },
  data() {
    return {
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 10, // 每页的数据条数
      searchVal: "",
      filters: {
        Url: "",
      },
      buttonList: [],
      buttonListmsg: "interface",
      users: [],
      statusList: [
        { Url: "激活", value: true },
        { Url: "禁用", value: false },
      ],
      page: 1,
      listLoading: false,
      currentRow: null,
      sels: [], //列表选中列

      addDialogFormVisible: false,
      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editFormRules: {
        Url: [{ required: true, message: "请输入接口地址", trigger: "blur" }],
      },
      //编辑界面数据
      editForm: {
        Id: 0,
        CreatedBy: "",
        Url: "",
        Name: "",
        Enabled: false,
      },

      addFormVisible: false, //新增界面是否显示
      addLoading: false,
      addFormRules: {
        Url: [{ required: true, message: "请输入接口地址", trigger: "blur" }],
      },
      //新增界面数据
      addForm: {
        CreatedBy: "",
        CreatedId: "",
        Url: "",
        Name: "",
        Enabled: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this.filters = {
        name: item.search,
      };
      this[item.Func].apply(this, item);
    },
    //性别显示转换
    formatEnabled: function (row, column) {
      return row.Enabled ? "正常" : "未知";
    },
    formatCreateTime: function (row, column) {
      return !row.CreatedOn || row.CreatedOn == ""
        ? ""
        : util.formatDate.format(new Date(row.CreatedOn), "yyyy-MM-dd");
    },
    //获取用户列表
    getInterfaces() {
      let para = {
        page: this.page,
        size: this.pageSize,
        key: this.filters.name,
      };
      getModuleListPage(para).then((res) => {
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
      });
    },
    //删除
    handleDel: function () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });

        return;
      }
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          //NProgress.start();
          let para = { id: row.Id };
          removeModule(para).then((res) => {
            if (util.isEmt.format(res)) {
              this.listLoading = false;
              return;
            }
            this.listLoading = false;
            if (res.data.success) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }

            this.getInterfaces();
          });
        })
        .catch(() => {});
    },
    //显示编辑界面
    handleEdit: function () {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });

        return;
      }
      this.editFormVisible = true;
      this.editForm = Object.assign({}, row);
    },
    //显示新增界面
    handleAdd() {
      this.addFormVisible = true;
      this.addForm = {
        CreatedBy: "",
        Url: "",
        Name: "",
        Enabled: "true",
      };
    },
    //编辑
    editSubmit: function () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            let para = Object.assign({}, this.editForm);

            para.ModifiedOn = util.formatDate.format(new Date(), "yyyy-MM-dd");

            editModule(para).then((res) => {
              if (util.isEmt.format(res)) {
                this.editLoading = false;
                return;
              }
              if (res.data.success) {
                this.editLoading = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["editForm"].resetFields();
                this.editFormVisible = false;
                this.getInterfaces();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    //新增
    addSubmit: function () {
      let _this = this;
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            let para = Object.assign({}, this.addForm);
            para.CreatedOn = util.formatDate.format(new Date(), "yyyy-MM-dd");
            para.ModifiedOn = para.CreatedOn;
            para.Deleted = false;

            var user = JSON.parse(window.localStorage.user);

            if (user && user.sub != null) {
              para.CreatedId = user.sub;
              para.CreatedBy = user.name;
            } else {
              this.$message({
                message: "用户信息为空，先登录",
                type: "error",
              });
              _this.$router.replace(
                _this.$route.query.redirect ? _this.$route.query.redirect : "/"
              );
            }
            console.info(para);
            addModule(para).then((res) => {
              if (util.isEmt.format(res)) {
                this.addLoading = false;
                return;
              }

              if (res.data.success) {
                this.addLoading = false;
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["addForm"].resetFields();
                this.addFormVisible = false;
                this.getInterfaces();
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    selsChange: function (sels) {
      this.sels = sels;
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getInterfaces();
    },
  },
  mounted() {
    this.getInterfaces();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style>
.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
</style>
